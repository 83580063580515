import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Question, QuestionsResult } from '../interfaces';
import { AuthService } from '../../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionsDataService {

  private uri: string = `${environment.apiUrl}question`;
  private headers:any ;

  constructor(
    private http: HttpClient
  ) {
    const jsonAuth = localStorage.getItem(AuthService.ATUH_STORAGE_KEY);
    if (jsonAuth) {
      const token = JSON.parse(jsonAuth)?.token;
      if (token) {
        this.headers = new HttpHeaders({
          enctype: 'multipart/form-data',
          authorization: 'bearer ' + token,
        });
      } 
    }
  }

  getQuestions(): Observable<QuestionsResult|any> {
    return this.http.get(`${environment.apiUrl}questionsAdmin`);
  }

  addQuestion(data: Question): Observable<Question|any> {
    return this.http.post(this.uri + '/add', data, { headers :this.headers });
  }

  updateQuestion(data: Question, id:any): Observable<Question|any> {
    return this.http.post(this.uri + '/update'+ `/${id}`, data, { headers :this.headers });
  }

  deleteQuestion(data: Question): Observable<null|any> {
    return this.http.put(this.uri + "/update_active" + `/${data.id}`, "");
  }
}
