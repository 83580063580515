import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CommonService } from '../../../../services/common/common.service';
import { ArticlesDataService, ArticlesColumnsService, CategoriesDataService } from '../../../services';
import { Article, ArticlesResult, CategoriesResult } from '../../../interfaces';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EditorService } from '../../../../services/editor/editor.service';

@Component({
  selector: 'app-post-form',
  templateUrl: './article-form.component.html',
  styleUrls: ['./article-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class ArticleFormComponent implements OnInit {

  public article: Article = {};
  public articleForm: FormGroup = new FormGroup({});
  public articles: Article[] = [];
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'en';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;
  public categories:any;
  selectedFile: File | null;

  public Editor = ClassicEditor;
  categoryValue: any;
  formData = new FormData();

  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private messageService: MessageService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private articlesDataService: ArticlesDataService,
    public articlesColumnsService: ArticlesColumnsService,
    public editorService: EditorService,
    public categoriesDataService: CategoriesDataService
  ) {
    this.selectedFile = null; // Initialize it to a default value, such as null
  }

  ngOnInit(): void {
    this.getCategories();
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.post !== 'undefined') {
      this.article = this.dynamicDialogConfig.data.post;
    }
  if (typeof this.article.id === 'undefined' || this.article.id === null) {
      this.article.id = null;
      this.article.active = false;
  }
    
    this.getArticles();
    this.prepareFrom();
  }


  getCategories(){
    this.categoriesDataService.getCategories().subscribe((response: CategoriesResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.categories = response.success.data;
      }
    }, (error: any) => {;
     
    });
  }

  getArticles(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.posts !== 'undefined' && this.dynamicDialogConfig.data.posts.length > 0) {
      this.articles = this.dynamicDialogConfig.data.posts;
      return;
    }
    this.articlesDataService.getArticles().subscribe((response: ArticlesResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.articles = response.results;
      }
    });
  }

  prepareFrom(): void {
    this.articleForm = new FormGroup({
      id: new FormControl(this.article.id, []),
      category: new FormControl(this.categoryValue, [Validators.required, ]),
      title: new FormControl(this.article.title, []),
      TitleEn: new FormControl(this.article.TitleEn, []),
      titleFr: new FormControl(this.article.titleFr, []),
      imagePath: new FormControl(this.article.imagePath, []),
      description: new FormControl(this.article.description, []),
      DescriptionEn: new FormControl(this.article.DescriptionEn, []),
      descriptionFr: new FormControl(this.article.descriptionFr, []),
     
    });
  }

  onChange(e:any) {
    this.categoryValue = e.value;
  }

  list() {
    this.router.navigate(['/posts']);
  }

  showErrors() {
      const invalidFields: string[] = [];
      Object.keys(this.articleForm.controls).forEach((field: any) => {
        const control = this.articleForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.articleForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.article = this.articleForm.value;
    this.disableSubmit = true;
    this.formData.append('title', this.articleForm ? this.articleForm.value.titleFr : null);
    this.formData.append('titleFr', this.articleForm ? this.articleForm.value.titleFr : null);
    this.formData.append('TitleEn', this.articleForm ? this.articleForm.value.TitleEn : null);
    this.formData.append('description', this.articleForm ? this.articleForm.value.descriptionFr : null);
    this.formData.append('descriptionFr', this.articleForm ? this.articleForm.value.descriptionFr : null);
    this.formData.append('DescriptionEn', this.articleForm ? this.articleForm.value.DescriptionEn : null);
    this.formData.append('category', this.categoryValue.id);
    if (this.article.id === null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  update(redirect: boolean) {
    this.articlesDataService.updateArticle(this.formData, this.articleForm.value.id).subscribe((data: any) => {
      this.disableSubmit = false;
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      console.warn(error);
    });
  }

  add(redirect: boolean) {
    this.articlesDataService.addArticle(this.formData).subscribe((data: any) => {
        this.disableSubmit = false;
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        console.warn(error);
    });
  }

  uploadPhoto(event: any): void {
    this.selectedFile = event.target.files[0] as File;
    if (this.selectedFile) {
      this.formData.append('picture', this.selectedFile);
    }
  }

}

@Component({
  selector: 'app-post-form',
  templateUrl: './article-form.component.html',
  styleUrls: ['./article-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogArticleFormComponent extends ArticleFormComponent {
}

