import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../services/cache/cache.service';

@Injectable({
  providedIn: 'root'
})
export class LocationDataService {

  private uri: string = `${environment.apiUrl}location/log`;
  private apiUrl = 'http://router.project-osrm.org/route/v1/driving';

  constructor(
    private http: HttpClient
  ) {

  }

  getLocation(parameters: {[param: string]:  string | string|string | string[]}): Observable<any> {
    return this.http.get(this.uri + '/' + parameters.day + '/' + parameters.month + '/' + parameters.year + '/' + parameters.phoneNumber);
  }


  getRoute(start: any, end: any): Observable<any> {
    const url = `${this.apiUrl}/${start[1]},${start[0]};${end[1]},${end[0]}?geometries=geojson`;
    return this.http.get(url);
  }
}
