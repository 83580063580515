<p-toast></p-toast>
<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="gift.id === null">{{ 'GIFT.NEWGIFT' | translate }}</span>
            <span *ngIf="gift.id !== null">{{ 'GIFT.EDITGIFT' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="giftForm" id="giftForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="giftForm" >
            <input type="hidden" name="id" id="giftId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

               
                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="giftNameFr" formControlName="nameFr" type="text" pInputText [required]="false"> 
                      <label for="giftNameFr" class="">{{ 'CONTENT.NAMEFR' | translate }}</label>
                    </span>
                  </div>
                </div>

                <div *ngIf="giftForm.controls['nameFr'].invalid && (giftForm.controls['nameFr'].dirty || giftForm.controls['nameFr'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="giftForm.controls['nameFr'].errors !== null">
                  <div *ngIf="giftForm.controls['nameFr'].errors.required">
                   {{ 'VALIDATION.NAMEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="giftnameEn" formControlName="nameEn" type="text" pInputText [required]="false"> 
                      <label for="giftnameEn" class="">{{ 'CONTENT.nameEn' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input inputId="giftStartTime" formControlName="description" type="text" pInputText [required]="true" >
                      <label for="giftStartTime" class="req">{{ 'CONTENT.DESCRIPTION' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="giftForm.controls['description'].invalid && (giftForm.controls['description'].dirty || giftForm.controls['description'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="giftForm.controls['description'].errors !== null">
                  <div *ngIf="giftForm.controls['description'].errors.required">
                   {{ 'CONTENT.DESCRIPTION' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input inputId="giftStartTime" formControlName="descriptionFr" type="text" pInputText [required]="true" >
                      <label for="giftStartTime" class="req">{{ 'CONTENT.DESCRIPTIONFR' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="giftForm.controls['descriptionFr'].invalid && (giftForm.controls['descriptionFr'].dirty || giftForm.controls['descriptionFr'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="giftForm.controls['descriptionFr'].errors !== null">
                  <div *ngIf="giftForm.controls['descriptionFr'].errors.required">
                   {{ 'CONTENT.DESCRIPTIONFR' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
                <div class="p-col-6">

                  <div class="p-field p-col-12">
                    <div class="p-inputgroup">
                      <span class="p-inputgroup-addon">
                        <i class="icon-marker-alt"></i>
                      </span>
                      <span class="p-float-label">
                        <input inputId="giftStartTime" formControlName="DescriptionEn" type="text" pInputText [required]="true" >
                        <label for="giftStartTime" class="req">{{ 'CONTENT.DescriptionEn' | translate }}</label>
                      </span>
                    </div>
                  </div>
                  <div *ngIf="giftForm.controls['DescriptionEn'].invalid && (giftForm.controls['DescriptionEn'].dirty || giftForm.controls['DescriptionEn'].touched)" class="alert alert-danger">
                    <ng-container *ngIf="giftForm.controls['DescriptionEn'].errors !== null">
                    <div *ngIf="giftForm.controls['DescriptionEn'].errors.required">
                     {{ 'CONTENT.DescriptionEn' | translate }}.
                    </div>
                    </ng-container>
                  </div>
  
                </div><!--/p-col-6-->
                <div class="p-col-6">

                  <div class="p-field p-col-12">
                    <div class="p-inputgroup">
                      <span class="p-inputgroup-addon">
                        <i class="icon-link"></i>
                      </span>
                      <span class="p-float-label">
                        <input id="giftPushStreamUrl" formControlName="requiredPointsNumber" type="text" pInputText [required]="false"> 
                        <label for="giftPushStreamUrl" class="">{{ 'CONTENT.REQUIREDNUMBEROFPOINTS' | translate }}</label>
                      </span>
                    </div>
                  </div>
  
                </div><!--/p-col-6-->
             
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-image"></i>
                    </span>

                    <input type="file"  pButton pRipple icon="pi pi-folder" styleClass="p-button-info"  class="w-100" (change)="uploadPhoto($event)" accept="image/*" />
                  </div>
                </div>

              </div><!--/p-col-6-->

              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="gift" formControlName="value" type="text" pInputText [required]="false"> 
                      <label for="giftPushStreamName" class="">{{ 'VALUE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
           

            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit">
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'ACTIONS.SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

