import { INavData } from '../coreui/sidebar';

export const WalkAndWinNavItems: INavData[] = [
  {
    name: 'DASHBOARD.DASHBOARD',
    url: '/dashboard',
    icon: 'sli-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    title: true,
    name: 'Walk and win Manager' 
  },
      {
        name: 'DASHBOARD.COMPETITIONS',
        url: '/competition',
        icon: 'sli-event'
      },
      {
        name: 'DASHBOARD.MEMBERS',
        url: '/members',
        icon: 'cil-running'
      },
      {
        name: 'DASHBOARD.GIFTS',
        url: '/gifts',
        icon: 'sli-calendar'
      },
      {
        name: 'DASHBOARD.CATEGORIES',
        url: '/categories',
        icon: 'sli-film'
      },
      {
   
        name: 'DASHBOARD.ARTICLES',
        url: '/articles',
        icon: 'cil-short-text'
      },
      //  {
   
      //   name: 'DASHBOARD.VIDEOS',
      //   url: '/videos',
      //   icon: 'cil-short-text'
      // },
      
      {
   
        name: 'Top 50',
        url: '/list',
        icon: 'cil-short-text'
      },
      {
   
        name: 'Location',
        url: '/location',
        icon: 'cil-short-text'
      },

      {
   
        name: 'Statistics',
        url: '/statistics',
        icon: 'cil-short-text'
      },

     

 
];

