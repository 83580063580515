import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { Column } from '../interfaces/column.interface';
import { Device, Member } from '../interfaces';
import { Notification } from '../interfaces';
import { User } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class GiftsColumnsService {

  public giftClasses: Device[] = [];
  public coaches: Notification[] = [];
  public users: User[] = [];
  public members:Member[]=[];

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,

  ) {
  }

  getColumns(): Column[] {
    return [
      { field: 'imagePath', sortField: 'imagePath', title: this.translate.instant('CONTENT.MOBILEPICTURE'), show: (this.navigation.getParamValue('giftClasses.imagePath.showFiled', true) && true), displayInList: true, type: 'imgValue'},
      { field: 'nameEn', sortField: 'nameEn', title: this.translate.instant('CONTENT.nameEn'), show: (this.navigation.getParamValue('gift.nameEn.showFiled', true) && true), displayInList: true, type: 'textValue'},
      { field: 'nameFr', sortField: 'nameFr', title: this.translate.instant('CONTENT.NAMEFR'), show: (this.navigation.getParamValue('gift.nameFr.showFiled', true) && true), displayInList: true, type: 'textValue'},
      //{ field: 'creationDate', sortField: 'creationDate', title: this.translate.instant('CREATIONDATE'), show: (this.navigation.getParamValue('gift.creationDate.showFiled', true) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      //{ field: 'updatedDate', sortField: 'updatedDate', title: this.translate.instant('UPDATEDATE'), show: (this.navigation.getParamValue('gift.updatedDate.showFiled', true) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      //{ field: 'endDate', sortField: 'endDate', title: this.translate.instant('ENDDATE'), show: (this.navigation.getParamValue('gift.endDate.showFiled', true) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT')},
      { field: 'description', sortField: 'description', title: this.translate.instant('CONTENT.DESCRIPTION'), show: (this.navigation.getParamValue('gift.description.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'descriptionFr', sortField: 'descriptionFr', title: this.translate.instant('CONTENT.DESCRIPTIONFR'), show: (this.navigation.getParamValue('gift.descriptionFr.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'DescriptionEn', sortField: 'DescriptionEn', title: this.translate.instant('CONTENT.DescriptionEn'), show: (this.navigation.getParamValue('gift.DescriptionEn.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'requiredPointsNumber', sortField: 'requiredPointsNumber', title: this.translate.instant('CONTENT.REQUIREDNUMBEROFPOINTS'), show: (this.navigation.getParamValue('gift.requiredPointsNumber.showFiled', false) && true), displayInList: true, type: 'textValue'},
      
      { field: 'actions', title: this.translate.instant('CONTENT.ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null},
    ];
  }


}
