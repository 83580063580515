import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { Column } from '../interfaces/column.interface';

@Injectable({
  providedIn: 'root'
})
export class ArticlesColumnsService {


  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
  ) {
  }
 
  getColumns(): Column[] {
    return [
      { field: 'imagePath', sortField: 'imagePath', title: this.translate.instant('CONTENT.PICTURE'), show: (this.navigation.getParamValue('posts.imagePath.showFiled', true) && true), displayInList: true, type: 'imgValue'},
     // { field: 'id', sortField: 'id', title: this.translate.instant('ID'), show: (this.navigation.getParamValue('posts.id.showFiled', true) && true), displayInList: true, type: 'numericValue' },
      { field: 'title', sortField: 'title', title: this.translate.instant('CONTENT.TITLE'), show: (this.navigation.getParamValue('posts.title.showFiled', true) && true), displayInList: true, type: 'textValue' },
      { field: 'TitleEn', sortField: 'TitleEn', title: this.translate.instant('CONTENT.TitleEn'), show: (this.navigation.getParamValue('posts.TitleEn.showFiled', true) && true), displayInList: true, type: 'textValue' },
      { field: 'titleFr', sortField: 'titleFr', title: this.translate.instant('CONTENT.TITLEFR'), show: (this.navigation.getParamValue('posts.titleFr.showFiled', true) && true), displayInList: true, type: 'textValue' },
      //{ field: 'status', sortField: 'status', title: this.translate.instant('STATUS'), show: (this.navigation.getParamValue('posts.status.showFiled', false) &&true), type: 'textValue', displayInList: true},
      { field: 'description', sortField: 'description', title: this.translate.instant('CONTENT.DESCRIPTION'), show: (this.navigation.getParamValue('posts.description.showFiled', true) && true), displayInList: true, type: 'textValue' },
      { field: 'DescriptionEn', sortField: 'DescriptionEn', title: this.translate.instant('CONTENT.DescriptionEn'), show: (this.navigation.getParamValue('posts.DescriptionEn.showFiled', true) && true), displayInList: true, type: 'textValue' },
      { field: 'descriptionFr', sortField: 'descriptionFr', title: this.translate.instant('CONTENT.DESCRIPTIONFR'), show: (this.navigation.getParamValue('posts.descriptionFr.showFiled', true) && true), displayInList: true, type: 'textValue' },
      //{ field: 'creationDate', sortField: 'creationDate', title: this.translate.instant('CREATEDAT'), show: (this.navigation.getParamValue('posts.creationDate.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT') },
      //{ field: 'updatedDate', sortField: 'updatedDate', title: this.translate.instant('MODIFIEDAT'), show: (this.navigation.getParamValue('posts.updatedDate.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT') },
      { field: 'actions', title: this.translate.instant('CONTENT.ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null },
    ];
  }

}
