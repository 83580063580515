import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { VideosDataService, VideosColumnsService } from '../../services';
import { DialogVideoFormComponent } from './video-form/video-form.component';
import { DialogVideoDetailsComponent } from './video-details/video-details.component';
import { Video, VideosResult } from '../../interfaces';

@Component({
  selector: 'app-posts',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class VideosComponent implements OnInit, OnDestroy {

  private videoAddDialog: DynamicDialogRef = new DynamicDialogRef();
  private videoEditDialog: DynamicDialogRef = new DynamicDialogRef();
  private videoDetailsDialog: DynamicDialogRef = new DynamicDialogRef();
  public videosToDelete: Video = {};
  public videos: Video[] = [];
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  private _selectedColumns: Column[] = [];

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private videosDataService: VideosDataService,
    private videosColumnsService: VideosColumnsService,
  ) {
  }

  ngOnInit(): void {
    this.isInitialized = true;
    this.titleService.setTitle('Videos - Management');
    this.primengConfig.ripple = true;
    this.getVideos();
  }

  ngOnDestroy(): void {
    this.isInitialized = false;
  }

  getVideos(): void {
    this.isLoading = true;
    if (!this.isInitialized) {
      return;
    }
    this.videosDataService.getVideos().subscribe((response: VideosResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();
        this.videos = response.success.data;
      }
    }, (error: any) => {;
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('UI.ERROR'), detail: this.translate.instant('UI.videoSNOTLOADED')});
    });
  }

  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.videosColumnsService.getColumns();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }

  @Input()
  get selectedColumns(): any[] {
      return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      // restore original order
      this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
      this.updateColumns();
  }

  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      this.navigation.setParamValue('posts.' + this.columns[i].field + '.showFiled', show);
    }
  }

  exportPdf() {
    ToolsService.exportPdf(this.videos, this.columns, 'Videos');
  }

  exportExcel() {
    ToolsService.exportExcel(this.videos, this.columns, 'Videos');
  }

  exportCsv() {
    ToolsService.exportCsv(this.videos, this.columns, 'Videos');
  }

  refresh() {
    this.getVideos();
  }

  videoAdd(): void {
    if (!this.navigation.getParamValue('posts.addInDialog', true)) {
      this.router.navigate(['/videos/add']);
      return;
    }
    this.videoAddDialog = this.dialogService.open(DialogVideoFormComponent, {
      header: '', // this.translate.instant('NEWvideo'),
      width: '80%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'add',
        post: {id: null},
      },
    });

    this.videoAddDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Video added'});
        this.getVideos();
      }
    });
  }

  videoEdit(video: Video): void {
    if (!this.navigation.getParamValue('posts.editInDialog', true)) {
      this.router.navigate(['/videos/edit/' + video.id]);
      return;
    }
    this.videoEditDialog = this.dialogService.open(DialogVideoFormComponent, {
      header: '', // this.translate.instant('EDITvideo') + ' ' + video.title,
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'edit',
        post: video,
       // users: this.postsColumnsService.users,
      },
    });

    this.videoEditDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'video updated'});
        this.getVideos();
      }
    });
  }

  videoDetails(video: Video): void {
    if (!this.navigation.getParamValue('posts.detailsInDialog', true)) {
      this.router.navigate(['/videos/details/' + video.id]);
      return;
    }
    this.videoDetailsDialog = this.dialogService.open(DialogVideoDetailsComponent, {
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        post: video,
      }
    });
  }

  videoDelete(video: Video): void {
    this.videosToDelete = video;
    this.messageService.clear();
    this.messageService.add({
      key: 'postDelete',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure you want delete ' + video.title + ' ?',
      detail: 'Confirm to proceed'
    });
  }

  onDeleteConfirm(): void {
    this.videosDataService.deleteVideo(this.videosToDelete).subscribe((res: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Video deleted'});
      this.getVideos();
    }, (error: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Video not deleted'});
    });
  }

  onDeleteReject(): void {
    this.videosToDelete = {};
    this.messageService.clear('postDelete');
  }

}
