import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { GiftsComponent } from './components/gifts/gifts.component';
import { GiftFormComponent } from './components/gifts/gift-form/gift-form.component';
import { GiftDetailsComponent } from './components/gifts/gift-details/gift-details.component';


import { QuestionsComponent } from './components/questions/questions.component';
import { QuestionFormComponent } from './components/questions/question-form/question-form.component';
import { QuestionDetailsComponent } from './components/questions/question-details/question-details.component';


import { MembersComponent } from './components/members/members.component';
import { MemberDetailsComponent } from './components/members/member-details/member-details.component';

import { ArticlesComponent } from './components/articles/articles.component';
import { ArticleFormComponent } from './components/articles/article-form/article-form.component';
import { ArticleDetailsComponent } from './components/articles/article-details/article-details.component';


import { VideosComponent } from './components/videos/videos.component';
import { VideoFormComponent } from './components/videos/video-form/video-form.component';
import { VideoDetailsComponent } from './components/videos/video-details/video-details.component';


import { CategoriesComponent } from './components/categories/categories.component';
import { CategoryFormComponent } from './components/categories/category-form/category-form.component';
import { CategoryDetailsComponent } from './components/categories/category-details/category-details.component';

// Import Containers
import { DefaultLayoutComponent } from '../containers';
import { CompetitionDetailsComponent } from './components/competition/competition-details/competition-details.component';
import { CompetitionFormComponent } from './components/competition/competition-form/competition-form.component';
import { competitionsComponent } from './components/competition/competition.component';
import { ListComponent } from './components/list/list.component';
import { LocationComponent } from './components/location/location.component';
import { StatsComponent } from './components/stats/stats.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        // // canActivate: [AuthGuard],
        data: {
          title: 'Dashboard'
        },
      },  
      // {
      //   path: 'notification',
      //   component: NotificationsComponent,
      //   // canActivate: [AuthGuard],
      //   data: {
      //     title: 'notification'
      //   },
      // },
      // {
      //   path: 'notification/details/:id',
      //   component: NotificationDetailsComponent,
      //   // canActivate: [AuthGuard],
      //   data: {
      //     title: 'Notification Details'
      //   },
      // },
      // {
      //   path: 'devices',
      //   component: DevicesComponent,
      //   // canActivate: [AuthGuard],
      //   data: {
      //     title: 'Devices'
      //   },
      // },
      // {
      //   path: 'devices/details/:id',
      //   component: DeviceDetailsComponent,
      //   // canActivate: [AuthGuard],
      //   data: {
      //     title: 'Device Details'
      //   },
      // },
     
      {
        path: 'competition',
        component: competitionsComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Competition'
        },
      },
      {
        path: 'competition/add',
        component: CompetitionFormComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'New Competition'
        },
      },
      {
        path: 'competition/edit/:id',
        component: CompetitionFormComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Edit Competition'
        },
      },
      {
        path: 'competition/details/:id',
        component: CompetitionDetailsComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Competition Details'
        },
      },
      {
        path: 'gifts',
        component: GiftsComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Gifts'
        },
      },
      {
        path: 'gifts/add',
        component: GiftFormComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'New Gift'
        },
      },
      {
        path: 'gifts/edit/:id',
        component: GiftFormComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Edit Gift'
        },
      },
      {
        path: 'gifts/details/:id',
        component: GiftDetailsComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Gift Details'
        },
      },
      {
        path: 'members',
        component: MembersComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Members'
        },
      },
      {
        path: 'members/details/:id',
        component: MemberDetailsComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Member Details'
        },
      }
      ,
      {
        path: 'articles',
        component: ArticlesComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Articles'
        },
      },
      {
        path: 'articles/add',
        component: ArticleFormComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'New Article'
        },
      },
      {
        path: 'articles/edit/:id',
        component: ArticleFormComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Edit Article'
        },
      },
      {
        path: 'articles/details/:id',
        component: ArticleDetailsComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Article Details'
        },
      },
      {
        path: 'videos',
        component: VideosComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Videos'
        },
      },
      {
        path: 'videos/add',
        component: VideoFormComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'New Video'
        },
      },
      {
        path: 'videos/edit/:id',
        component: VideoFormComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Edit Video'
        },
      },
      {
        path: 'videos/details/:id',
        component: VideoDetailsComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Video Details'
        },
      },
      {
        path: 'categories',
        component: CategoriesComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Categories'
        },
      },
      {
        path: 'categories/add',
        component: CategoryFormComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'New Category'
        },
      },
      {
        path: 'categories/edit/:id',
        component: CategoryFormComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Edit Category'
        },
      },
      {
        path: 'categories/details/:id',
        component: CategoryDetailsComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Category Details'
        },
      },
      {
        path: 'list',
        component: ListComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'List'
        },
      },
      {
        path: 'location',
        component: LocationComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Location'
        },
      },
      {
        path: 'statistics',
        component: StatsComponent,
        data: {
          title: 'Statistics'
        },
      },
      {
        path: 'questions',
        component: QuestionsComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Questions'
        },
      },
      {
        path: 'questions/add',
        component: QuestionFormComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'New Question'
        },
      },
      {
        path: 'questions/edit/:id',
        component: QuestionFormComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Edit Question'
        },
      },
      {
        path: 'questions/details/:id',
        component: QuestionDetailsComponent,
        // canActivate: [AuthGuard],
        data: {
          title: 'Question Details'
        },
      },
      

    ]
  },
  { path: '**', component: DefaultLayoutComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class WalkWinRoutingModule { }

