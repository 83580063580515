import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import moment from 'moment';
import { CommonService } from '../../../../services/common/common.service';
import { Question, QuestionsResult } from '../../../interfaces/question.interface';
import { QuestionsDataService } from '../../../services/questions-data.service';
import { QuestionsColumnsService } from '../../../services/questions-columns.service';

@Component({
  selector: 'app-question-session-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class QuestionFormComponent implements OnInit {

  public question: Question = {};
  public questionForm: FormGroup = new FormGroup({});
  public questions: Question[] = [];
  public isLoading: boolean = false;
  public redirect = true;
  public disableSubmit = false;
  selectedFile: File | null;
  formData = new FormData();

  //imagePath = new FormControl(this.Question.imagePath, []);


  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private messageService: MessageService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private questionsDataService: QuestionsDataService,
    public questionColumnsService: QuestionsColumnsService,
  ) {
    this.selectedFile = null; // Initialize it to a default value, such as null
  }

  ngOnInit(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.question !== 'undefined') {
      this.question = this.dynamicDialogConfig.data.question;
    }
    if (typeof this.question.id === 'undefined' || this.question.id === null) {
      this.question.id = null;
      this.question.createdAt = moment().set({minute: 0, second: 0, millisecond: 0}).format('YYYY-MM-DD\THH:mm:ssZ');
      this.question.isActive = false;
    }
    this.getQuestions();
    this.prepareFrom();
  }

  getQuestions(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.question !== 'undefined' && this.dynamicDialogConfig.data.question.length > 0) {
      this.questions = this.dynamicDialogConfig.data.question;
      return;
    }
    this.questionsDataService.getQuestions().subscribe((response: QuestionsResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.questions = response.results;
      }
    });
  }

  prepareFrom(): void {
   // this.question.creationDate = moment(this.question.creationDate).toDate();
    this.questionForm = new FormGroup({
      id: new FormControl(this.question.id, []),
    
      title: new FormControl(this.question.title, []),
      TitleEn: new FormControl(this.question.TitleEn, []),
      titleFr: new FormControl(this.question.titleFr, []),
      points: new FormControl(this.question.points, []),
    });
  }

  list() {
    this.router.navigate(['/questions']);
  }

  showErrors() {
      const invalidFields: string[] = [];
      Object.keys(this.questionForm.controls).forEach((field: any) => {
        const control = this.questionForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.questionForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.question = this.questionForm.value;
    this.disableSubmit = true;
    this.formData.append('name', this.questionForm ? this.questionForm.value.nameFr : null);
    this.formData.append('nameFr', this.questionForm ? this.questionForm.value.nameFr : null);
    this.formData.append('nameEn', this.questionForm ? this.questionForm.value.nameEn : null);
    this.formData.append('description', this.questionForm ? this.questionForm.value.description : null);
    this.formData.append('DescriptionEn', this.questionForm ? this.questionForm.value.DescriptionEn : null);
    this.formData.append('descriptionFr', this.questionForm ? this.questionForm.value.descriptionFr : null);
    this.formData.append('requiredPointsNumber', this.questionForm ? this.questionForm.value.requiredPointsNumber : null);
    this.formData.append('value', this.questionForm ? this.questionForm.value.value : null);

    if (this.question.id === null) {
      this.add(redirect, this.formData);
    } else {
      this.update(redirect, this.formData);
    }
    return false;
  }

  uploadPhoto(event: any): void {
    this.selectedFile = event.target.files[0] as File;
    if (this.selectedFile) {
      this.formData.append('picture', this.selectedFile);
    }
  }

  add(redirect: boolean, data:any) {
    this.questionsDataService.addQuestion(data).subscribe((data: any) => {
      this.disableSubmit = false;
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      console.warn(error);
    });
  }

  update(redirect: boolean, data:any) {
    this.questionsDataService.updateQuestion(data, this.questionForm.value.id).subscribe((data: any) => {
        this.disableSubmit = false;
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        console.warn(error);
    });
  }

}


@Component({
  selector: 'app-question-session-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogQuestionFormComponent extends QuestionFormComponent {
}

