import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Video, VideosResult } from '../interfaces';
import { AuthService } from '../../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class VideosDataService {

  private uri: string = `${environment.apiUrl}list-video`;
  private headers:any ;

  constructor(
    private http: HttpClient
  ) {
    const jsonAuth = localStorage.getItem(AuthService.ATUH_STORAGE_KEY);
    if (jsonAuth) {
      const token = JSON.parse(jsonAuth)?.token;
      if (token) {
        this.headers = new HttpHeaders({
          enctype: 'multipart/form-data',
          authorization: 'bearer ' + token,
        });
      } 
    }
  }

  getVideos(): Observable<VideosResult|any> {
    return this.http.get(this.uri + "/all");
  }

  addVideo(data: any): Observable<Video|any> {
    return this.http.post(this.uri + '/add', data, { headers :this.headers });
  }

  updateVideo(data: any,id:any): Observable<Video|any> {
    return this.http.post(this.uri + '/update'+ `/${id}`, data, { headers :this.headers });
  }

  deleteVideo(data: Video): Observable<null|any> {
    return this.http.put(this.uri + "/update_active" + `/${data.id}`, "");
  }

}
