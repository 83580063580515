<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="video.id === null">{{ 'VIDEO.NEWVIDEO' | translate }}</span>
            <span *ngIf="video.id !== null">{{ 'VIDEO.EDITVIDEO' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="videoForm" id="videoForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="videoForm" >
            <input type="hidden" name="id" id="videoId" formControlName="id" />
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="far fa-newspaper"></i>
                    </span>
                    <span class="p-float-label">
                      <p-dropdown  inputId="category" [autoDisplayFirst]="false" formControlName="category" [options]="categories" (onChange)="onChange($event)" optionLabel="name" [required]="true" [filter]="true"></p-dropdown>
                      <label for="category" class="req">{{ 'CATEGORY.CATEGORY' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div *ngIf="videoForm.controls['category'].invalid && (videoForm.controls['category'].dirty || videoForm.controls['category'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="videoForm.controls['category'].errors !== null">
                  <div *ngIf="videoForm.controls['category'].errors.required">
                   {{ 'VIDEO.VIDEOTYPEREQUIRED' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-tags"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoTitle" formControlName="title" type="text" pInputText [required]="false"> 
                      <label for="videoTitle" class="">{{ 'CONTENT.TITLE' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-tags"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoTitleEn" formControlName="TitleEn" type="text" pInputText [required]="false"> 
                      <label for="videoTitleEn" class="">{{ 'CONTENT.TitleEn' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-tags"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="videoTitleFr" formControlName="titleFr" type="text" pInputText [required]="false"> 
                      <label for="videoTitleFr" class="">{{ 'CONTENT.TITLEFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            
            <div class="p-fluid p-grid">
              
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-image"></i>
                    </span>

                    <input type="file"  pButton pRipple icon="pi pi-folder" styleClass="p-button-info"  class="w-100" (change)="uploadPhoto($event)" accept="image/*" />
                  </div>
                </div>

              </div><!--/p-col-6-->

            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              <div class="p-col-12">

                <div class="p-field p-col-12">
                  <label for="description" class="req">{{ 'CONTENT.DESCRIPTION' | translate }}</label>
                  <ckeditor  inputId="description" formControlName="description"  [editor]="Editor" [config]="editorService.config" type="content"  [required]="true" ></ckeditor>
                </div>
                <div *ngIf="videoForm.controls['description'].invalid && (videoForm.controls['description'].dirty || videoForm.controls['description'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="videoForm.controls['description'].errors !== null">
                  <div *ngIf="videoForm.controls['description'].errors.required">
                   {{ 'CONTENT.DESCRIPTION' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-12">

                <div class="p-field p-col-12">
                      <label for="descriptionFr" class="req">{{ 'CONTENT.DESCRIPTIONFR' | translate }}</label>
                      <ckeditor  inputId="descriptionFr" formControlName="descriptionFr"  [editor]="Editor" [config]="editorService.config" type="content"  [required]="true" ></ckeditor>
                </div>
                <div *ngIf="videoForm.controls['descriptionFr'].invalid && (videoForm.controls['descriptionFr'].dirty || videoForm.controls['descriptionFr'].touched)" class="alert alert-danger">
                  <ng-container *ngIf="videoForm.controls['descriptionFr'].errors !== null">
                  <div *ngIf="videoForm.controls['descriptionFr'].errors.required">
                   {{ 'CONTENT.DESCRIPTIONFR' | translate }}.
                  </div>
                  </ng-container>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
          
            <div class="p-fluid p-grid">

            <div class="p-col-12">

              <div class="p-field p-col-12">
                <div class="p-field p-col-12">
                  <label for="DescriptionEn" class="req">{{ 'CONTENT.DescriptionEn' | translate }}</label>
                  <ckeditor  inputId="DescriptionEn" formControlName="DescriptionEn"  [editor]="Editor" [config]="editorService.config" type="content"  [required]="true" ></ckeditor>
            </div>
              </div>
              <div *ngIf="videoForm.controls['DescriptionEn'].invalid && (videoForm.controls['DescriptionEn'].dirty || videoForm.controls['DescriptionEn'].touched)" class="alert alert-danger">
                <ng-container *ngIf="videoForm.controls['DescriptionEn'].errors !== null">
                <div *ngIf="videoForm.controls['DescriptionEn'].errors.required">
                 {{ 'CONTENT.DescriptionEn' | translate }}.
                </div>
                </ng-container>
              </div>

            </div><!--/p-col-6-->
            
            </div>

            <div class="p-fluid p-grid">

              <div class="p-col-12">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!postForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'ACTIONS.SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

