import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import { VideosColumnsService } from '../../../services';
import { Video } from '../../../interfaces';
import { Column } from '../../../interfaces/column.interface';

@Component({
  selector: 'app-post-details',
  templateUrl: './video-details.component.html',
  styleUrls: ['./video-details.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class VideoDetailsComponent implements OnInit {

  public video:  Video = {};
  public columns: Column[] = [];

  constructor(
    private router: Router,
    private dynamicDialogConfig: DynamicDialogConfig,
    private videosColumnsService:  VideosColumnsService,
  ) { }

  ngOnInit(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.post !== 'undefined') {
      this.video = this.dynamicDialogConfig.data.post;
    }
    this.columns = this.videosColumnsService.getColumns();

    for (let i = 0; i <= this.columns.length; i++) {
      if (this.columns[i].field === "actions") {
        this.columns.splice(i, 1);
      }
    }
  }


}
@Component({
  selector: 'app-post-details',
  templateUrl: './video-details.component.html',
  styleUrls: ['./video-details.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogVideoDetailsComponent extends  VideoDetailsComponent {
}

