import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';
import { Credentials } from '../../interfaces/credentials.interface';
import { Message } from 'primeng/api';
import { LoginResponse } from '../../interfaces/login-response.interface';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public credentials: Credentials = {
    phoneNumber: '',
    pinCode: ''
  };
  public msgs: Message[] = [];
  public isLoading = false;
  public selectedLanguage:any= 'en'; // Default language is English

  languageOptions = [
    { label: 'French', value: 'fr' },
    { label: 'English', value: 'en' },
  ];
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService

  ) { }

  ngOnInit(): void {
    this.selectedLanguage = 'en';
    this.route.queryParams
      .subscribe((params: any) => {
        if (typeof params.phoneNumber !== 'undefined') {
          this.credentials.phoneNumber = params.phoneNumber;
        }
        if (typeof params.pinCode !== 'undefined') {
          this.credentials.pinCode = params.pinCode;
        }
      }
      );
    const auth = localStorage.getItem(AuthService.ATUH_STORAGE_KEY);
    if (auth !== null) {
      const loginResponse: LoginResponse = JSON.parse(auth);
      this.router.navigate(['/dashboard']);
      if (loginResponse.data) {
        const t = parseInt(moment().format('x'), 10);
        if (t > loginResponse.data.expires) {
          this.router.navigate(['/dashboard']);
        }
      }
    }
  }

  login(): void {
    if (this.credentials.phoneNumber === '' || this.credentials.pinCode === '') {
      this.msgs = [];
      this.msgs.push({ severity: 'warn', summary: this.translate.instant('UI.EMPTYFIELDS'), detail: this.translate.instant('UI.USERNAMEPASSWORDREQUIRED') });

      return;
    }
    this.isLoading = true;
    this.authService.login(this.credentials).subscribe((response) => {
      this.isLoading = false;
      // this.router.navigate(['dashboard']);
      this.router.navigate(['/dashboard']);
      if (response.success) {
        localStorage.setItem(AuthService.ATUH_STORAGE_KEY, JSON.stringify(response.success));
        sessionStorage.setItem(AuthService.ATUH_STORAGE_KEY, JSON.stringify(response.success));
        this.msgs = [];
        this.msgs.push({ severity: 'success', summary: this.translate.instant('UI.LOGINSUCCESS'), detail: this.translate.instant('UI.REDIRECTED') });
        //setTimeout(() => {
          this.router.navigate(['/dashboard']);
       // }, 2000);
      } else {
        this.msgs = [];
        this.msgs.push({ severity: 'error', summary: this.translate.instant('UI.INCORRECTCREDENTIALS'), detail: this.translate.instant('UI.ERRORLOGIN') });
      }
    }, (error) => {
    
      this.isLoading = false;
      this.msgs = [];

      this.msgs.push({ severity: 'error', summary: this.translate.instant('UI.INCORRECTCREDENTIALS'), detail: this.translate.instant('UI.ERRORLOGIN') });
    });
  }

  forgotPassword(): void {
    this.router.navigate(['/forgot-password']);
  }

  onLanguageChange(): void {
    localStorage.setItem('locale', this.selectedLanguage );
    this.translate.setDefaultLang(this.selectedLanguage);
    this.translate.use(this.selectedLanguage);
    
  }

}
