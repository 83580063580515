import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CommonService } from '../../../../services/common/common.service';
import { CategoriesDataService, CategoriesColumnsService } from '../../../services';
import { Category } from '../../../interfaces';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EditorService } from '../../../../services/editor/editor.service';
@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class CategoryFormComponent implements OnInit {

  public category: Category = {};
  public categoryForm: FormGroup = new FormGroup({});
  public redirect = true;
  public disableSubmit = false;
  public Editor = ClassicEditor;
 
  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private categoriesDataService: CategoriesDataService,
    public  categoriesColumnsService: CategoriesColumnsService,
    public editorService: EditorService,
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (typeof this.category.id === 'undefined' || this.category.id === null) {
        this.category.id = null;
        this.category.isActive = false;
      }
    });
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.category !== 'undefined') {
      this.category = this.dynamicDialogConfig.data.category;
    }
    this.prepareFrom();
  }

  prepareFrom(): void {
    this.categoryForm = new FormGroup({
      id: new FormControl(this.category.id, []),
      name: new FormControl(this.category.name, [Validators.required,]),
      nameEn: new FormControl(this.category.nameEn, []),
      nameFr: new FormControl(this.category.nameFr, []),
      description: new FormControl(this.category.description, []),
      DescriptionEn: new FormControl(this.category.DescriptionEn, []),
      descriptionFr: new FormControl(this.category.descriptionFr, []),
    });
  }

  list() {
    this.router.navigate(['/categories']);
  }

  showErrors() {
    const invalidFields: string[] = [];
    Object.keys(this.categoryForm.controls).forEach((field: any) => {
      const control = this.categoryForm.get(field);
      if (control !== null) {
        control.markAsTouched({ onlySelf: true });
        if (control.invalid) {
          invalidFields.push(field);
        }
      }
    });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.categoryForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.category = this.categoryForm.value;
    this.disableSubmit = true;
    if (this.category.id === null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  add(redirect: boolean) {
    this.categoriesDataService.addCategory(this.category).subscribe((data: any) => {
      this.disableSubmit = false;
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({ refresh: true });
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      console.warn(error);
    });
  }

  update(redirect: boolean) {
    this.categoriesDataService.updateCategory(this.category).subscribe((data: any) => {
      this.disableSubmit = false;
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({ refresh: true });
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      console.warn(error);
    });
  }

}

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogCategoryFormComponent extends CategoryFormComponent {
}

