<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="category.id === null">{{ 'CATEGORY.NEWCATEGORY' | translate }}</span>
            <span *ngIf="category.id !== null">{{ 'CATEGORY.EDITCATEGORY' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="categoryForm" id="categoryForm" novalidate (ngSubmit)="submitForm(true)"
            [formGroup]="categoryForm">
            <input type="hidden" name="id" id="category" formControlName="id" />
            <div class="p-fluid p-grid">
            </div>
         
           
            
            <!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
   
              <!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-tag"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="categoryName" formControlName="name" type="text" pInputText [required]="true">
                      <label for="categoryName" class="req">{{ 'CONTENT.NAME' | translate }}</label>
                    </span>
                  </div>
                </div>
                <div
                  *ngIf="categoryForm.controls['name'].invalid && (categoryForm.controls['name'].dirty || categoryForm.controls['name'].touched)"
                  class="alert alert-danger">
                  <ng-container *ngIf="categoryForm.controls['name'].errors !== null">
                    <div *ngIf="categoryForm.controls['name'].errors.required">
                      {{ 'VALIDATION.NAMEREQUIRED' | translate }}.
                    </div>
                  </ng-container>
                </div>

              </div>
              <!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="categorynameEn" formControlName="nameEn" type="text" pInputText [required]="false">
                      <label for="categorynameEn" class="">{{ 'CONTENT.nameEn' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div>
            </div>
            <!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
             
              <!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-marker-alt"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="categoryNameFr" formControlName="nameFr" type="text" pInputText [required]="false">
                      <label for="categoryNameFr" class="">{{ 'CONTENT.NAMEFR' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div>
              <div class="p-col-12">

                <div class="p-field p-col-12">
                  <label for="description" class="req">{{ 'CONTENT.DESCRIPTION' | translate }}</label>
                  <ckeditor  inputId="description" formControlName="description"  [editor]="Editor" [config]="editorService.config" type="content"  [required]="true" ></ckeditor>
                </div>

              </div>
            </div>
            <!--/p-fluid p-grid-->
            
                         <div class="p-fluid p-grid">
              <div class="p-col-12">

                <div class="p-field p-col-12">
                  <div class="p-field p-col-12">
                    <label for="DescriptionEn" class="req">{{ 'CONTENT.DescriptionEn' | translate }}</label>
                    <ckeditor  inputId="DescriptionEn" formControlName="DescriptionEn"  [editor]="Editor" [config]="editorService.config" type="content"  [required]="true" ></ckeditor>
                  </div>
                </div>

              </div>
              <!--/p-col-12-->
              <div class="p-col-12">

                <div class="p-field p-col-12">
                  <div class="p-field p-col-12">
                    <label for="descriptionFr" class="req">{{ 'CONTENT.DESCRIPTIONFR' | translate }}</label>
                    <ckeditor  inputId="descriptionFr" formControlName="descriptionFr"  [editor]="Editor" [config]="editorService.config" type="content"  [required]="true" ></ckeditor>
                  </div>
                </div>

              </div>
              <!--/p-col-12-->
            </div>

                  
            <div class="p-fluid p-grid">
              <div class="p-col-12">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right"
                  [disabled]="disableSubmit">
                 
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'ACTIONS.SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div>
    <!--/p-col-12-->
  </div>
  <!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div>
<!--/container-fluid container-fullw-->
<!-- end: FORM -->