// Importing necessary modules from Angular and third-party libraries
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, Message } from 'primeng/api';
import { CommonService } from '../../../../services/common/common.service';
import { CompetitionsDataService, GiftsDataService } from '../../../services';
import { Competition } from '../../../interfaces';
import { TranslateService } from '@ngx-translate/core';

// Component decorator and metadata
@Component({
  selector: 'app-competition-reservation-form',
  templateUrl: './competition-form.component.html',
  styleUrls: ['./competition-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class CompetitionFormComponent implements OnInit {

  // Properties for the component
  public competition: Competition = {};
  public competitionForm: FormGroup = new FormGroup({});
  public redirect = false;
  public gifts: any;
  public gift: any;
  public msgs: Message[] = [];
  

  // Constructor with dependency injection
  constructor(
    public common: CommonService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private competitionsDataService: CompetitionsDataService,
    private messageService: MessageService,
    public GiftsDataService: GiftsDataService,
    private translate: TranslateService
  ) {
  }

  // Lifecycle hook - ngOnInit
  ngOnInit(): void {
    // Display informational message using PrimeNG MessageService
    this.msgs.push({ severity: 'info', summary: this.translate.instant('UI.COMPETITIONALERT'), detail: this.translate.instant('UI.COMPETITIONALERTMESSAGE') });

    // Load gifts and set initial competition data if available
    this.getGifts();
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.competition !== 'undefined') {
      this.competition = this.dynamicDialogConfig.data.competition;
      this.gift = this.competition.gift;
    }

    // Initialize competition properties and prepare form
    if (typeof this.competition.id === 'undefined' || this.competition.id === null) {
      this.competition.id = null;
      this.competition.active = false;
    }
    this.prepareFrom();
  }

  // Method to fetch gifts from the service
  getGifts() {
    this.GiftsDataService.getGifts().subscribe((res: any) => {
      this.gifts = res.success.data;
    }, (error: any) => {
      // Handle error if needed
    });
  }

  // Event handler for gift selection
  onChange(e: any) {
    this.gift = e.value;
  }

  // Method to prepare the form with initial data
  prepareFrom(): void {
    this.competition.active = false;
    let startD: any;
    let endD: any;
    if (this.competition.startDate != undefined && this.competition.endDate != undefined) {
      startD = new Date(((this.competition.startDate).toString()));
      endD = new Date((this.competition.endDate).toString());
    }
    this.competitionForm = new FormGroup({
      id: new FormControl(this.competition.id, []),
      name: new FormControl(this.competition.name),
      nameEn: new FormControl(this.competition.nameEn),
      nameFr: new FormControl(this.competition.nameFr),
      objective: new FormControl(this.competition.objective),
      //description: new FormControl(this.competition.description),
      startDate: new FormControl(startD, []),
      endDate: new FormControl(endD, []),
      gift: new FormControl(this.gift, [Validators.required,]),
      active: new FormControl(this.competition.active, []),
    });
  }

  // Method to display form errors
  showErrors() {
    const invalidFields: string[] = [];
    Object.keys(this.competitionForm.controls).forEach((field: any) => {
      const control = this.competitionForm.get(field);
      if (control !== null) {
        control.markAsTouched({ onlySelf: true });
        if (control.invalid) {
          invalidFields.push(field);
        }
      }
    });
  }

  // Method to submit the form
  submitForm() {
    this.redirect = true;
    if (!this.competitionForm.valid) {
      this.showErrors();
      this.redirect = false;
    } else {
      // Prepare data for submission
      let data = {
        "name": this.competitionForm.value.name,
        "nameFr": this.competitionForm.value.nameFr,
        "nameEn": this.competitionForm.value.nameEn,
        "startDate": this.competitionForm.value.startDate,
        "objective": this.competitionForm.value.objective,
        //"description": this.competitionForm.value.description,
        "endDate": this.competitionForm.value.endDate,
        "giftId": this.competitionForm.value.gift.id,
        "isActive": true,
      };

      // Submit form data based on whether it's an add or update operation
      if (this.competition.id === null) {
        this.competitionsDataService.addCompetition(data).subscribe((response: Competition | any) => {
          this.redirect = false;
          this.dynamicDialogRef.close({ refresh: true });
        }, (error: any) => {;
          this.redirect = false;
          this.messageService.clear();
          this.messageService.add({severity: 'error', summary: "", detail: error.error.errors});
        });
      } else {
        this.competitionsDataService.updateCompetition(data, this.competitionForm.value.id).subscribe((response: Competition | any) => {
          this.redirect = false;
          this.dynamicDialogRef.close({ refresh: true });
        }, (error: any) => {;
          this.redirect = false;
          this.messageService.clear();
          this.messageService.add({severity: 'error', summary: "", detail: error.error.errors});
        });
      }
    }
  }
}

// Inherited component for dialog usage
@Component({
  selector: 'app-competition-reservation-form',
  templateUrl: './competition-form.component.html',
  styleUrls: ['./competition-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogCompetitionFormComponent extends CompetitionFormComponent {
}
